import App from "../App";
import { createBrowserRouter } from "react-router-dom";
import { AuthProvider } from "../context/authentication";
import { GuestRoutes } from "../utils/GuestRoutes";
import { PrivateRoutes } from "../utils/PrivateRoutes";

// import { getIsExperimental } from "../utils/env";

import LazyComponentLoader from "./lazyLoader";

const createRouter = () => {
  const privateRoutes = [
    {
      path: "/aei-market-price",
      element: <LazyComponentLoader componentName="AutoExpertMarketPrice" />,
    },
    {
      path: "/aei-market-price-coef",
      element: <LazyComponentLoader componentName="AutoExpertMarketPriceCoef" />,
    },
    {
      path: "/aei-ins-value",
      element: <LazyComponentLoader componentName="AutoExpertInsValue" />,
    },
    {
      path: "/aei-tech-data",
      element: <LazyComponentLoader componentName="AutoExpertTechData" />,
    },
    {
      path: "/aei-vin",
      element: <LazyComponentLoader componentName="AutoExpertVIN" />,
    },
    {
      path: "/aei-new",
      element: <LazyComponentLoader componentName="AutoExpertNew" />,
    },
    {
      path: "/aei-new-demo",
      element: <LazyComponentLoader componentName="AutoExpertNewDemo" />,
    },
    {
      path: "/aei-easy",
      element: <LazyComponentLoader componentName="AutoExpertEasy" />,
    },
    {
      path: "/aei-easy/:evalId/edit",
      element: <LazyComponentLoader componentName="AutoExpertEasyEdit" />,
    },
    {
      path: "/clients/bulstrad/market-price",
      element: <LazyComponentLoader componentName="BulstradMarketPrice" />,
    },
    {
      path: "/clients/dzi/market-price",
      element: <LazyComponentLoader componentName="DZIMarketPrice" />,
    },
    {
      path: "/clients/uniqa/market-price",
      element: <LazyComponentLoader componentName="UniqaMarketPrice" />,
    },
    {
      path: "/clients/ozk/identification",
      element: <LazyComponentLoader componentName="OZKEvaluationPage" />,
    },
  ];
  const profileRoutes = [
    {
      path: "/profile/settings",
      element: <LazyComponentLoader componentName="ProfileSettings" />,
    },
    {
      path: "/profile/history",
      element: <LazyComponentLoader componentName="ProfileHistory" />,
    },
    {
      path: "/profile/applications",
      element: <LazyComponentLoader componentName="Applications" />,
    },
    {
      path: "/profile/applications/:applicationName",
      element: <LazyComponentLoader componentName="ApplicationEdit" />,
    },
  ];
  const administrationRoutes = [
    {
      path: "/administration/settings",
      element: <LazyComponentLoader componentName="CompanySettings" />,
    },
    {
      path: "/administration/applications",
      element: <LazyComponentLoader componentName="Applications" />,
    },
    {
      path: "/administration/applications/:applicationName",
      element: <LazyComponentLoader componentName="ApplicationEdit" />,
    },
    {
      path: "/administration/users",
      element: <LazyComponentLoader componentName="CompanyUsers" />,
    },
    {
      path: "/administration/users/create",
      element: (
        <LazyComponentLoader
          componentName="CompanyUserDetails"
          params={{ mode: "create" }}
        />
      ),
    },
    {
      path: "/administration/users/:userId",
      element: (
        <LazyComponentLoader
          componentName="CompanyUserDetails"
          params={{ mode: "edit" }}
        />
      ),
    },
    {
      path: "/administration/history",
      element: <LazyComponentLoader componentName="CompanyHistory" />,
    },
  ];

  const guestRoutes = [
    {
      path: "/info/aei-tech-data",
      element: <LazyComponentLoader componentName="AutoExpertTechDataLanding" />,
    },
    {
      path: "/info/aei-ins-value",
      element: <LazyComponentLoader componentName="AutoExpertInsValueLanding" />,
    },
    {
      path: "/info/aei-market-price",
      element: <LazyComponentLoader componentName="AutoExpertMarketPriceLanding" />,
    },
    {
      path: "/info/aei-easy",
      element: <LazyComponentLoader componentName="AutoExpertEasyLanding" />,
    },
    {
      path: "/info/aei-vin",
      element: <LazyComponentLoader componentName="AutoExpertVINLanding" />,
    },
    {
      path: "/info/aei-new",
      element: <LazyComponentLoader componentName="AutoExpertNewLanding" />,
    },
    {
      path: "/info/aei-new-demo",
      element: <LazyComponentLoader componentName="AutoExpertNewDemoLanding" />,
    },
    {
      path: "clients/porsche/login",
      element: <LazyComponentLoader componentName="PorscheLoginPage" />,
    },
    {
      path: "clients/ozk/login",
      element: <LazyComponentLoader componentName="OZKLoginPage" />,
    },
    {
      path: "login",
      element: <LazyComponentLoader componentName="Login" />,
    },
    {
      path: "create-account",
      element: <LazyComponentLoader componentName="Register" />,
    },
    {
      path: "forgot-password",
      element: <LazyComponentLoader componentName="RequestResetPassword" />,
    },
    {
      path: "reset-password",
      element: <LazyComponentLoader componentName="ResetPassword" />,
    },
  ];

  // TODO: Remove this when the page is ready
  // if (getIsExperimental()) {
  //   guestRoutes.push();
  //   privateRoutes.push();
  // }
  // guestRoutes.push();
  // privateRoutes.push();

  return createBrowserRouter([
    {
      element: (
        <AuthProvider>
          <App />
        </AuthProvider>
      ),
      errorElement: <LazyComponentLoader componentName="NotFound" />,
      children: [
        {
          element: <LazyComponentLoader componentName="LandingLayout" />,
          children: [
            { path: "/", element: <LazyComponentLoader componentName="Home" /> },
            {
              path: "/email-verification/:code",
              element: <LazyComponentLoader componentName="EmailVerification" />,
            },
            { path: "/about-us", element: <LazyComponentLoader componentName="AboutUs" /> },
            {
              element: <GuestRoutes />,
              children: guestRoutes,
            },
          ],
        },
        {
          element: <PrivateRoutes />,
          children: [
            {
              element: <LazyComponentLoader componentName="ApplicationsLayout" />,
              children: privateRoutes,
            },
            {
              element: <LazyComponentLoader componentName="AdministrationLayout" />,
              children: administrationRoutes,
            },
            {
              element: <LazyComponentLoader componentName="ProfileLayout" />,
              children: profileRoutes,
            },
          ],
        },
      ],
    },
  ]);
};

export { createRouter };
